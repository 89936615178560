.Search__Navigation {}
@media (--viewport-menu) {
    .Search__Navigation {
        height: 100%;
        overflow: hidden;
        padding: 1em;
        display: flex;
        align-items: center;
    }   
}

.Link__Link[data-parent="Search__Navigation"],
.Button__Button_Search__Navigation {
    border: 0px;
    padding: 0px;
    background-color: transparent;
    color: white;
    cursor: pointer;
    outline: none;
    vertical-align: text-top;
    display: inline-block;
    min-width: 1em;
    min-height: 1em;
}

.Link__Link[data-parent="Search__Navigation"] {
    display: none;
}
@media (--viewport-menu) {
    .Link__Link[data-parent="Search__Navigation"] {
        display: inline-block;
    }
}
.Link__Link[data-parent="Search__Navigation"]:before {
    display: none;
}

.Link__Link[data-parent="Search__Navigation"] svg {
    width: 1.25em;
    height: 1.25em;
}

.Search__Navigation__Form {
    background-color: var(--color-gold);
    color: white;
    width: 100%;
    overflow: hidden;
    height: var(--desktop-menu-height);
}
@media (--viewport-menu) {
    .Search__Navigation__Form {
        position: absolute;
        width: 0%;
        transform-origin: right;
        height: var(--desktop-menu-height);
        right: 0px;
        top: 0px;
        z-index: var(--z-orbit);
        will-change: width;
        transition: width var(--default-transition-speed);
    }
    .open > .Search__Navigation__Form {
        width: 100%;
    }
}

.Search__Navigation__Form_inner {
    display: flex;
    height: 100%;
    padding: 0.5rem 2em;
    align-items: center;    
}

.Search__Navigation__Form input {
    background-color: transparent;
    border: 0px;
    outline: none;
    color: white;
    flex: 1 0 auto;
}

.Link__Link_Search__Navigation[type='submit'] svg {
    height: 1em;
    width: 1em;
}

.Search__Navigation__Overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: transparent;
    z-index: var(--z-atmosphere);
    pointer-events: none;
}
.open > .Search__Navigation__Overlay {
    pointer-events: all;
}
