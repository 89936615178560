.Masthead__Photo {
    background-size: auto 50vh;
    padding: var(--space);
    padding-top: 50vh;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
}
@media(--viewport-menu) {
    .Masthead__Photo {
        background-size: cover;
        padding: 0px var(--space-quad);
        min-height: 40rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .Masthead__Photo:before {
        position: absolute; content: " "; display: block; top: 0px; left: 0px; width: 100%; height: 100%;
        background-color: black;
        opacity: 0.5;
        z-index: var(--z-crust);
    }
}

.Masthead__Photo > * {
    max-width: var(--default-text-width);
}
@media(--viewport-menu) {
    .Masthead__Photo > * {
        max-width: 25rem;
        z-index: var(--z-surface);
        position: relative;
    }
}

.Headline__Headline_level-1[data-parent="Masthead__Photo"] {
    padding-top: var(--space);
    color: var(--color-gold);
    font-size: var(--type-size-2);
}
@media(--viewport-menu) {
    .Headline__Headline_level-1[data-parent="Masthead__Photo"] {
        color: white;
        font-size: var(--type-size-4);
        padding-top: 0px;
        line-height: 1.25;
    }
}

@media(--viewport-menu) {
    .Text__Text[data-parent="Masthead__Photo"] {
        color: white;
    }
}

.Link__Clickthrough[data-parent="Masthead__Photo"] {
    color: var(--color-gold);
}
@media(--viewport-menu) {
    .Link__Clickthrough[data-parent="Masthead__Photo"] .icon {
        color: white;
    }
}

@media(--viewport-menu) {
    .scroll-down[data-parent="Masthead__Photo"] {
        display: block;
        width: 2rem;
        height: auto;
        margin-top: var(--space-triple);
    }
}

.Separator[data-parent="Masthead__Photo"] {
    margin-top: calc(var(--space) * 1.5);
}