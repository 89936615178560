.Keel__Keel {
    min-height: 40rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    color: white;
    text-align: center;
    padding: var(--space);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Keel__Keel > * {
    z-index: var(--z-surface);
    position: relative;
}

.Headline__Headline_level-4[data-parent="Keel__Keel"] {
    font-family: var(--type-face-headline);
    font-size: var(--type-size-3);
    line-height: 1;
}
@media (--viewport-menu) {
    .Headline__Headline_level-4[data-parent="Keel__Keel"] {
        font-size: var(--type-size-5);
    }
}

.Text__Text[data-parent="Keel__Keel"] {
    font-size: var(--type-size-1);
    line-height: 1.25;
}
@media (--viewport-menu) {
    .Text__Text[data-parent="Keel__Keel"] {
        line-height: 2;
    }   
}

.Link__Link[data-parent="Keel__Keel"] {
    padding: var(--space) var(--space-double);
    margin: var(--space) auto;
    background-color: rgba(255,255,255, 0.75);
    font-family: var(--type-face-mechanical);
    font-weight: bold;
    font-size: var(--type-size--1);
    text-transform: uppercase;
    color: var(--color-gold);
    display: block;
    width: 15rem;
}
@media (--viewport-menu) {
    .Link__Link[data-parent="Keel__Keel"] {
        display: inline-block;
        min-width: 15rem;
        margin: 0px var(--space-half);
    }
}

.Link__Link[data-parent="Keel__Keel"] .icon {
    color: var(--color-black);
    vertical-align: bottom;
    margin-right: var(--space-half);
    height: 1em;
    width: 1.25em;
}