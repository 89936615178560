/* Desktop */
@media (--viewport-menu) {
    .Navigation__Secondary {
        height: var(--desktop-menu-height);
        text-align: center;
        display: block;
        overflow: visible;
        background-color: white;
    }    
}

@media (--viewport-menu) {
    .Navigation__Item__Secondary {
        display: inline-block;
    }
}

@media (--viewport-menu) {
    .Navigation__Menu__Secondary {
        width: 100%;
        background-color: transparent;
        transform: none; 
    }
}

@media (--viewport-menu) {
    .Navigation__Item__Primary.active .Navigation__Menu__Secondary.level-0 {
        display: block;
    }
}

@media (--viewport-menu) {
    .Navigation__Menu__Secondary .Link__Navigation {
        color: var(--color-navy);
        font-size: var(--type-size--2);
        box-shadow: inset 0px 0px 0px 0px var(--color-gold);
        overflow: hidden;
        transition: box-shadow var(--default-transition-speed), color var(--default-transition-speed);
        will-change: box-shadow, color;
    }
    .Navigation__Menu__Secondary .Link__Navigation:hover,
    .Navigation__Menu__Secondary .active > .Link__Navigation {
        color: white;
        box-shadow: inset 0px -var(--desktop-menu-height) 0px 0px var(--color-gold);
    }
}