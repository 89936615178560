.page-template-template-practice-index .Masthead__Page .Separator {
    display: none;
}

.practice_primary-areas {
  justify-content: center;
}

.practice_general-areas {
  margin-bottom: var(--space-triple);
  align-items: flex-end;
}

.practice_area_separator {
    margin: var(--space-double) auto;
    max-width: 80%;
}

@media (--viewport-desktop) {
    .Bucket__Practice[data-parent="practice_general-areas"] {
      width: calc(100% / 4);
    }
}

.Link__Clickthrough[data-link-direction=back][data-parent="practice-area"] .icon {
  top: 0.5em;
}

body.appeals .Headline__Headline_level-1 {
  width: 50rem;
}

.practice_faq + .practice_get-in-touch {
  margin-top: var(--space-quad);
}