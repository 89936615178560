.site__footer {
    background-color: var(--color-navy);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: white;
}
@media (--viewport-menu) {
    .site__footer {
        flex-wrap: nowrap;
        padding-right: var(--space-double);
    }
}

[class*="site__footer__"] {
    padding: var(--space);
}
@media (--viewport-small) {
    [class*="site__footer__"] {
        padding: var(--space-double) var(--space);
    }
}
@media (--viewport-menu) {
    [class*="site__footer__"] {
        padding: var(--space-double) 0px var(--space-double) var(--space-double);
        vertical-align: top;
    }
}

@media (--viewport-menu) {
    nav[class*="site__footer__"] {
        flex: 0 1 calc(100% / 3);
        max-width: 16rem;
    }
}

.Headline__Headline_level-3[data-parent="site__footer"] {
    font-size: var(--type-size-1);
    margin-bottom: var(--space-half);
    text-transform: none;
}

.Logo__Logo[data-parent="site__footer"] {
    padding: var(--space-double) var(--space);
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    order: 1;
    width: 100%;
}
@media (--viewport-mini) {
    .Logo__Logo[data-parent="site__footer"] {
        flex: 0 0 50%;
    }
}
@media (--viewport-menu) {
    .Logo__Logo[data-parent="site__footer"] {
        flex: 0 1 calc(18rem + var(--space-double));
    }
}
.Logo__Logo[data-parent="site__footer"] img {
    width: 15rem;
    max-width: 100%;
    height: auto;
}

.site__footer__Map {
    background-image: url(../images/map.png);
    background-size: cover;
    background-position: center;
    order: 2;
    width: 100%;
    height: 15rem;
}
@media (--viewport-mini) {
    .site__footer__Map {
        width: 50%;
        flex: 0 0 50%;
        height: auto;
    }
}
@media (--viewport-menu) {
    .site__footer__Map {
        width: 300px;
        flex: 0 0 300px;
        display: none;
    }
}
@media (--viewport-desktop) {
    .site__footer__Map {
        order: 2;
        display: block;
    }
}

.footer_menu-wrapper {
  order: 3;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
}

.site__footer__Contact {
    order: 2;
    width: 100%;
    text-align: center;
    margin-bottom: var(--space);
}
@media (--viewport-mini) {
    .site__footer__Contact {
        width: 50%;
        flex-basis: 50%;
        text-align: left;
        margin-bottom: 0px;
    }
}
@media (--viewport-menu) {
    .site__footer__Contact {
        flex: 0 1 calc(100% / 3);
        max-width: 16rem;
        order: 3;
    }
}

.Address__Address[data-parent="site__footer"] {
    font-size: var(--type-size--1);
    margin-bottom: var(--space-half);
}

.Address__Address[data-parent="site__footer"] .addr_line_1 {
    display: block;
}

.Address__Address[data-parent="site__footer"] .addr_line_2 {
    display: block;
}

.Address__Address[data-parent="site__footer"] [itemprop="addressLocality"]:after {
    content: ", ";
}

.site__footer__Contact [itemprop="telephone"] {
    font-size: var(--type-size--1);
}

.site__footer__Contact [itemprop="telephone"] a {
    color: white;
}

.site__footer__Contact [itemprop="faxNumber"] {
    font-size: var(--type-size--1);
    margin-bottom: var(--space-half);
}

.site__footer__Contact [itemprop="faxNumber"] a {
    color: white;
}

.Link__Clickthrough[data-parent="directions_link"] {
    font-size: var(--type-size--1);
    color: var(--color-gold);
    font-weight: 500;
}

.footer-nav-menu {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.footer-nav-menu a {
    color: white;
    text-decoration: none;
    font-size: var(--type-size--2);
}

.footer-nav-menu .active a {
    border-bottom: 1px solid var(--color-gold);
}

.site__footer__Links {
    display: none;
}
@media (--viewport-menu) {
    .site__footer__Links {
        display: inline-block;
        order: 4;
    }
}

.site__footer__Practice {
    order: 1;
    text-align: center;
    width: 100%;
}
@media (--viewport-mini) {
  .site__footer__Practice {
    flex: 0 0 50%;
    text-align: left;
  }
}
@media (--viewport-menu) {
    .site__footer__Practice {
        order: 4;
    }
}

.colophon {
    font-size: calc(var(--type-size-base) * 0.55556);
    background-color: var(--color-navy);
    color: white;
    padding: var(--space-half) var(--space);
    text-align: center;
    line-height: 1.5;
    order: 5;
    width: 100%;
}
@media (--viewport-menu) {
    .colophon {
        text-align: right;
    }
}