.Masthead__Profile {
    width: var(--default-content-width);
    max-width: 100%;
    margin: 0px auto;
}
@media (--viewport-menu) {
  .Masthead__Profile {
    padding: var(--space-double) 6rem;
  }
}

.Link__Clickthrough[data-link-direction="back"][data-display-on="mobile"] {
    position: relative;
    color: var(--color-gold);
    padding-left: 1.5em;
}

.Link__Clickthrough[data-link-direction="back"][data-display-on="mobile"] .icon {
    position: absolute;
    left: 0px;
    top: 0px;
    transform: rotate(180deg);
}

.Photo__Photo[data-parent="Masthead__Profile"] {
  margin-top: var(--space);
}

.Headline__Headline_level-1[data-parent="Masthead__Profile"] {
    font-size: var(--type-size-2);
    line-height: 1.25;
}
@media (--viewport-menu) {
    .Headline__Headline_level-1[data-parent="Masthead__Profile"] {
        font-size: var(--type-size-3);
    }
}

.Headline__Headline_level-2[data-parent="Masthead__Profile"] {
    font-size: var(--type-size-1);
}
@media (--viewport-menu) {
    .Headline__Headline_level-2[data-parent="Masthead__Profile"] {
        font-size: var(--type-size-2);
    }
}

.attorney-meta[data-parent="Masthead__Profile"] {
    padding-top: var(--space-half);
    padding-bottom: calc(var(--space-half) - var(--space-quarter));
}
@media (--viewport-menu) {
    .attorney-meta[data-parent="Masthead__Profile"] {
      padding-top: var(--space);
      padding-bottom: calc(var(--space) - var(--space-quarter));
    }
}

@media (--viewport-menu-inverse) {
    .Masthead__Profile .Separator {
        display: none;
    }
}

.Link__Block[data-parent="Masthead__Profile"] {
    display: inline-block;
    margin-top: var(--space-half);
    font-size: var(--type-size--2);
    margin-right: var(--space-third);
}
.Link__Block[data-parent="Masthead__Profile"]:last-child {
    margin-right: 0px;
}
@media (--viewport-menu) {
    .Link__Block[data-parent="Masthead__Profile"] {
        padding: 1em 2em;
        margin-top: 0px;
        font-size: var(--type-size--1);
    }
}

.Link__Block[data-parent="Masthead__Profile"] .rating-number {
  color: var(--color-gold);
}

.Link__Link[data-parent="Masthead__Profile"] {
    display: block;
    color: currentColor;
    font-size: var(--type-size--2);
    vertical-align: top;
    text-align: left;
    margin-bottom: var(--space-half);  
}
.Link__Link[data-parent="Masthead__Profile"]:last-child {
    margin-right: 0px;
}
@media (--viewport-menu) {
    .Link__Link[data-parent="Masthead__Profile"] {
        display: inline-block;
        text-align: center;
        margin-right: var(--space);      
    }
}

.Link__Link[data-parent="Masthead__Profile"] .destination-name {
    color: var(--color-gold);
}

.Text__Corpus[data-parent="Masthead__Profile"] {
  text-align: left;
  padding: var(--space) 0px;
}
@media (--viewport-menu) {
  .Text__Corpus[data-parent="Masthead__Profile"] {
    columns: 2;
    width: 100%;
  }
}

.Masthead__Profile .profile-recognized {
  padding: var(--space-half);
  background-color: var(--color-navy);
  color: var(--color-gold);
  text-align: center;
  position: relative;
  font-family: var(--type-face-mechanical);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0px -var(--space);
}
@media (--viewport-small) {
  .Masthead__Profile .profile-recognized {
    padding: calc(var(--space) + var(--space-half));
  }
  .Masthead__Profile .profile-recognized:after {
    content: "";
    display: block;
    position: absolute;
    top: var(--space);
    left: var(--space);
    bottom: var(--space);
    right: var(--space);
    border: var(--default-border-width) solid var(--color-gold);
    z-index: 1;
  }
}
@media (--viewport-menu) {
  .Masthead__Profile .profile-recognized {
    margin: 0px -6rem;    
  }
}


.Masthead__Profile .profile-recognized_inner {
  padding: var(--space-double) 0px;
  border: 0.25rem double var(--color-gold);
  position: relative;
  z-index: 10;
}
@media (--viewport-small) {
  .Masthead__Profile .profile-recognized_inner {
    border: var(--default-border-width) solid var(--color-gold);
    padding: var(--space-triple) 0px;
  }
}


.Masthead__Profile .profile-recognized_intro {
  border-bottom: 0.25rem double var(--color-gold);
  padding: 0px var(--space);
  padding-bottom: var(--space-double);
}
@media (--viewport-small) {
  .Masthead__Profile .profile-recognized_intro {
    border-bottom: var(--default-border-width) solid var(--color-gold);
    padding-bottom: var(--space-triple);
  }
}

.Masthead__Profile .profile-recognized_intro .intro-text {
  margin-bottom: var(--space-half);
  font-weight: bold;
}

.Masthead__Profile .recognitions_list {
  list-style: none;
  margin: 0px;
  margin-top: var(--space-half);
  padding: 0px;
  padding-top: var(--space-double);
  text-align: center;
}
@media (--viewport-small) {
  .Masthead__Profile .recognitions_list {
    border-top: var(--default-border-width) solid var(--color-gold);    
  }
}

.Masthead__Profile .profile-recognized .single-recognition {
  margin-top: var(--space);
  display: inline-block;
  width: 16rem;
  max-width: 100%;
}

.Masthead__Profile .profile-recognized .single-recognition:after {
  content: "";
  display: block;
  width: 5rem;
  margin: 0.5rem auto;
  height: var(--default-border-width);
  background-color: var(--color-gold);
}

.Headline__Headline_level-5[data-parent="profile-recognized"] {
  font-family: var(--type-face-mechanical);
  font-size: var(--type-size-0);
  color: white;
}

.Masthead__Profile .profile-recognized .single-recognition a {
  font-size: var(--type-size--1);
}

.Masthead__Profile .masthead-bottom-sep {
    max-width: 80%;
    clear: both;
}

.Link__Clickthrough[data-type="profile-masthead"] {
  margin-top: var(--space);
}