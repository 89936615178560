.post_index_header {
  /*margin-bottom: 0px;*/
}

.post_index_title.Headline__Headline_level-1 {
  margin: 0px auto; 
  padding-bottom: 0px;
}

body.blog .Masthead__Featured {
  padding-top: 0px;
  margin-bottom: 0px;
}

.post_index_list {
  position: relative;
  padding-top: 0px;
}
@media (--viewport-small) {
  .post_index_list {
    padding-top: 4rem;
  }
}

.blog_post-sort {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space);
}
@media (--viewport-small) {
  .blog_post-sort {
    flex-direction: row;
    position: absolute;
    width: 100%;
    left: var(--space);
    right: var(--space);
    top: 0px;
    justify-content: flex-start;
  }
}
@media (--viewport-menu) {
  .blog_post-sort {
    justify-content: center;
  }
}

.blog_post-sort > * {
  min-height: 2.25rem;
  background-color: white;
  margin-bottom: var(--space-half) !important;
  width: 15rem;
  max-width: 100%;
}
@media (--viewport-small) {
  .blog_post-sort > * {
    margin: 0px var(--space-half);
  }
}


.blog_post-sort .Catalog__Catalog[data-style=dropdown] {
}
@media (--viewport-small) {
  .blog_post-sort .Catalog__Catalog[data-style=dropdown] {
    width: 15rem;
  }
}

.blog_post-sort .Search__Form {
  display: flex;
  border: 1px solid var(--color-gold);
  max-height: 2.25rem;
}

.blog_post-sort .Search__Form input {
  background: transparent;
  border: 0px;
  padding: 0px 0.5em;
  flex: 1 1 auto;
}
.blog_post-sort .Search__Form input::placeholder {
  font-size: var(--type-size--1);
  font-family: var(--type-face-mechanical);
  text-transform: uppercase;  
  opacity: 1;
  line-height: 2;
}

.blog_post-sort .Search__Form .Button__Button_Search {
  background: transparent;
  border: 0px;
  padding: 0px 0.5em;
  display: flex;
  align-content: center;
}

.blog_post-sort .Search__Form .Button__Button_Search svg {
  color: var(--color-gold);
  width: 0.75em;
  height: 0.75em;
}

.post_index_list .article_grid {
  align-items: flex-start;
}