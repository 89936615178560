.Text__Corpus[data-body-block-type="basic"] {
  text-align: left;
}
.Text__Corpus:after {
  content: "";
  display: block;
  clear: both;
}

.Text__Corpus > * {
    break-inside: avoid;
}

.Text__Corpus h1,
.Text__Corpus h2,
.Text__Corpus h3,
.Text__Corpus h4,
.Text__Corpus h5,
.Text__Corpus p {
  margin: 0px;
  padding-bottom: 1.5em;
}

.Text__Corpus h1,
.Text__Corpus h2,
.Text__Corpus h3,
.Text__Corpus h4,
.Text__Corpus h5 {
  padding-bottom: 0.15em;
}

.Text__Corpus > ul,
.Text__Corpus > ol {
  margin: 0px;
  padding-left: 1em;
  padding-bottom: 1.5em;
}

.Text__Corpus > ul li,
.Text__Corpus > ol li {
  margin-bottom: 0.25em;
}