article.single-post .entry-content {
  text-align: left;
  width: 50rem;
  max-width: 100%;
  margin: 0px auto;
}

article.single-post .entry-title {
  text-transform: capitalize;
  line-height: 1.25;
}

article.single-post .Catalog__Catalog[data-parent="single-post"] {
  display: inline-block;
  margin: 0px; padding: 0px;
  list-style: none;
}

article.single-post .Catalog__Catalog[data-parent="single-post"] li {
  display: inline;
}
article.single-post .Catalog__Catalog[data-parent="single-post"] li:after {
  content: " | ";
  color: var(--color-black);
}
article.single-post .Catalog__Catalog[data-parent="single-post"] li:last-child:after {
  content: "";
}

.single-post_meta {
  font-family: var(--type-face-mechanical);
  font-size: var(--type-size--1);
  text-transform: uppercase;
}

.social-media_share {
  display: flex;
  align-items: center;
  margin-top: var(--space-third);
}
@media (--viewport-menu) {
  .social-media_share {
    justify-content: center;    
  }
}

.Social__Social[data-parent="single-post"] {
  display: inline-block;
}

.Social__Social[data-parent="single-post"] ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: inline-block;
}

.Social__Social[data-parent="single-post"] li {
  display: inline-block;
  margin-left: var(--space-half);
}

.Social__Social[data-parent="single-post"] a {
  display: block;
  text-align: top;
}

.Social__Social[data-parent="single-post"] .icon {
  display: block;
}

.Social__Social[data-parent="single-post"] svg {
  width: 1.25em;
  height: 1.25em;
}

article.single-post header .Separator {
  margin-bottom: var(--space);
}