body.search {
  background-color: var(--color-sand);
}

.search_results_wrapper {
}

.search_results_wrapper article {
  max-width: 20rem;
  text-align: left;
  margin-bottom: var(--space-double);
}

.search_results_wrapper .entry-title {
  line-height: 1.333;
}


.search_results_header {
  margin-bottom: var(--space);
  text-align: left;
}

.search_results_header_term {
  font-family: var(--type-face-mechanical);
  text-transform: uppercase;
  font-size: var(--type-size--1);
}

.search_results_header_term_the-term {
  color: var(--color-gold);
  font-size: var(--type-size-1);
  font-family: var(--type-face-headline);
  text-transform: none;
}

.rounded-search .Search__Form {
  border: var(--default-border-width) solid var(--color-black);
  max-width: 18rem;
  border-radius: 1.2rem;
  padding: 0.2rem var(--space-half);
  display: flex;
  margin-top: var(--space-half);
}

.rounded-search .Search__Form .Button__Button_Search {
  display: flex;
  align-content: center;
}

.rounded-search .Search__Form .Button__Button_Search .icon {
  color: var(--color-gold);
  height: 0.75rem;
  width: 0.75rem;
  stroke: 2px;
}

.rounded-search .Search__Form .search-field {
  flex: 1 1 auto;
}

.rounded-search .Search__Form input,
.rounded-search .Search__Form button
 {
  border: 0px;
  background: transparent;
}

.rounded-search .Search__Form input::placeholder {
  font-size: var(--type-size--1);
  line-height: 2;
}

.search_no-results {
  text-align: left;
  margin-top: var(--space);
}