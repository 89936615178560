.Ebook__Ebook {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-color: var(--color-sand);
    overflow: hidden;
    transition: height var(--default-transition-speed), padding var(--default-transition-speed);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--space);
    padding-bottom: var(--space-double);
}
@media (--viewport-menu-inverse) {
    .Ebook__Ebook {
        /* Because the background images are done using inline styles,
            which don't support media queries, we need to do this to hide
            backgrounds on mobile. */
        background-image: none !important;   
    }
}
@media (--viewport-menu) {
    .Ebook__Ebook {
        display: block;
        height: 28rem;
        padding: 2rem var(--space-double);
    }
}
@media (--viewport-desktop) {
    .Ebook__Ebook {
        height: 32rem;
        padding: 4rem var(--space-double);
    }
}
@media (--viewport-menu-inverse) {
    .Ebook__Ebook[data-align="right"] {
        background-color: rgba(var(--color-beige-base), 0.75);
    }
}


.Ebook__Ebook .book-cover {
    box-shadow: 6px -1px 15px 0px rgba(0,0,0,0.29);
    height: auto;
    order: 1;
    max-width: 40vw;
    margin: var(--space);
}
@media (--viewport-menu) {
    .Ebook__Ebook .book-cover {
        margin: 0px;
        will-change: transform;
        transform: translateY(2rem);
        transition: transform 1s, width var(--default-transition-speed);
        width: 19rem;
    }
    .Ebook__Ebook .book-cover.shown {
        transform: translateY(-1rem);
    }
}
@media (--viewport-desktop) {
    .Ebook__Ebook .book-cover {
        transform: translateY(3rem);
        width: 22rem;
    }
    .Ebook__Ebook .book-cover.shown {
        transform: translateY(-3rem);
    }
}

@media (--viewport-menu) {
    .Ebook__Ebook[data-align="left"] .book-cover {
        margin-right: var(--space-half);
    }
}
@media (--viewport-desktop) {
    .Ebook__Ebook[data-align="left"] .book-cover {
        margin-right: var(--space-quad);
    }
}
@media (--viewport-menu) {
    .Ebook__Ebook[data-align="right"] .book-cover {
        margin-left: var(--space-half);
    }
}
@media (--viewport-desktop) {
    .Ebook__Ebook[data-align="right"] .book-cover {
        margin-left: var(--space-quad);
    }
}

.Ebook__Ebook .blurb {
    display: inline-block;
    vertical-align: top;
    transition: width var(--default-transition-speed);
    order: 2;
}
@media (--viewport-menu) {
    .Ebook__Ebook .blurb {
        width: 27rem;
    }
}
@media (--viewport-desktop) {
    .Ebook__Ebook .blurb {
        width: 30rem;
    }   
}

.Headline__Headline_level-3[data-parent="Ebook__Ebook"] {
    font-size: var(--type-size-1);
    line-height: 1.25;
    border-bottom: var(--default-border-width) solid var(--color-gold);
    padding-bottom: var(--space-half);
}
@media (--viewport-menu) {
.Headline__Headline_level-3[data-parent="Ebook__Ebook"] {
        font-size: calc(var(--type-size-0) * 1.778);
        padding: 0px var(--space) var(--space-half) var(--space);
    }    
}

.Text__Text[data-parent="Ebook__Ebook"] {
    padding: var(--space-half) var(--space);
}

.Link__Slab[data-parent="Ebook__Ebook"] {
  z-index: var(--z-surface);
  padding: var(--space-half) var(--space);
  margin: var(--space) auto;
  background-color: rgba(255,255,255, 0.75);
  font-family: var(--type-face-mechanical);
  font-weight: bold;
  font-size: var(--type-size--1);
  text-transform: uppercase;
  display: block;
  width: 10rem;
  border: var(--default-border-width) var(--color-gold) solid;
}
@media (--viewport-menu) {
  .Link__Slab[data-parent="Ebook__Ebook"] {
    display: inline-block;
    min-width: 12rem;
    margin: 0px var(--space-half);
  }
}