.case_post-sort {
    margin-top: var(--space-double);
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.case_post-sort-header {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.case_post-sort-header .list-name {
  margin-top: var(--space);
}

.case_post-sort.dropdown_wrapper_container {
  margin-top: 0px;
  padding-left: var(--space);
  padding-right: var(--space);
}

.case_post-sort .Headline__Headline_level-3 {
    margin-bottom: var(--space);
}

.case_post-sort .Catalog__Catalog[data-style="dropdown"] {
    margin-top: var(--space-third);
}
@media (--viewport-menu) {
    .case_post-sort .Catalog__Catalog[data-style="dropdown"] {
        margin-left: auto; margin-right: auto;
    }
}

.case_posts[data-body-block-type=basic] {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
@media (--viewport-small) {
  .case_posts[data-body-block-type=basic] {
    padding: var(--space-half);
    margin-bottom: 0px;
  }
}

@media (--viewport-small) {
    .case_posts .Bucket__LCase {
        display: inline-block;
        width: calc(100% / 2.05);
        flex: 0 1 calc(100% / 2);
        vertical-align: top;
        padding: var(--space);
        margin-bottom: var(--space);
    }
}
@media (--viewport-menu) {
    .case_posts .Bucket__LCase {
        width: calc(100% / 3.05);
        flex: 0 1 calc(100% / 3);
    }
}