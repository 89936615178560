.Navigation__Item__Primary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: static;
    overflow: hidden;
    flex: 0 0 auto;
    font-size: var(--type-size--1);
}
@media (--viewport-menu) {
    .Navigation__Item__Primary {
        display: inline-block;
    }
}

.Navigation__Item__Primary.parent {
    order: -1;
}

.Navigation__Item.submenu_toggle.up {
    order: -2;
}

@media (--viewport-menu) {
    .Link__Navigation {
        color: white;
        box-shadow: inset 0px 0px 0px 0px var(--color-gold);
        transition: box-shadow var(--fast-transition-speed);
        will-change: box-shadow;
        display: flex;
        align-items: center;
    }
    .Link__Navigation:hover {
        box-shadow: inset 0px -0.333em 0px 0px var(--color-gold);
    }
}