.testimonial_post-sort {
    margin: var(--space) auto;
    width: 15rem;
}

.testimonial_list_wrapper {
    column-width: 15rem;
    column-gap: var(--space-half);
}

.testimonials_wrapper .Bucket__Testimonial {
    background-color: var(--color-navy);
    break-inside: avoid;
}

.testimonial_wrapper {
    padding-bottom: var(--space-half);
}