.Paginate__Paginate {
    text-align: center;
    padding: var(--space-double) var(--space);
}

.Paginate__Paginate_counter {
    background-size: 100%;
    background-position: center;
    font-family: var(--type-face-mechanical);
    font-weight: bold;
    font-size: var(--type-size--1);
    background-repeat: no-repeat;
    display: inline-block;
    text-align: center;
    position: relative;
    color: var(--color-beige);
    transition: color 0.25s linear;
    padding: 0px 0.25em;
}
.Paginate__Paginate_counter:hover {
    color: var(--color-gold);
}

.current > .Paginate__Paginate_counter {
    color: var(--color-gold);
}

.Paginate__Paginate_shield.icon {
    width: 3em;
    height: 3em;
    color: var(--color-gold);
    stroke-width: 2px;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    opacity: 0.25;
    transition: stroke-dashoffset 0.5s linear, opacity 0.25s linear;
}

.current > .Paginate__Paginate_counter .Paginate__Paginate_shield.icon {
    stroke-dashoffset: 0;
    opacity: 1;
}

.Paginate__Paginate_counter:hover .Paginate__Paginate_shield.icon {
    stroke-dashoffset: 0;
    opacity: 1;
}

.Paginate__Paginate_number {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0.25em;
    font-size: 1.5em;
}
.current > .Paginate__Paginate_counter .Paginate__Paginate_number {
    cursor: default;    
}

[class*='Paginate__Paginate_arrow_'].icon {
    color: var(--color-gold);
    stroke-width: 1;
    height: 3em;
    width: 1em;
}

.Paginate__Paginate .page-numbers.dots {
  position: relative;
  top: -0.7em;
}

@media (--viewport-menu-inverse) {
  .Paginate__Paginate .page-numbers {
    display: none;
  }
  .Paginate__Paginate .page-numbers.prev,
  .Paginate__Paginate .page-numbers.next,
  .Paginate__Paginate .page-numbers.current {
    display: inline-block;
  }
/*  .Paginate__Paginate .page-numbers:nth-last-child(2) {
    display: inline-block;
    font-size: var(--type-size--1);
    width: auto;
    left: -0.75em;
    top: -1em;
    position: relative;
  }
  .Paginate__Paginate .page-numbers:nth-last-child(2) .icon {
    display: none;
  }
  .Paginate__Paginate .page-numbers:nth-last-child(2) .Paginate__Paginate_number {
    position: static;
  }
  .Paginate__Paginate .page-numbers:nth-last-child(2) .Paginate__Paginate_number:before {
    content: "/";
  }*/
}