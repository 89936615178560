.Catalog__Catalog[data-style="attorney-grid"] {
    text-align: center;
    padding: var(--space);
    list-style: none;
    margin: 0px auto;
    width: var(--wide-content-width);
    max-width: 100%;
    flex-wrap: wrap;
    display: flex;
}
@media (--viewport-menu) {
    .Catalog__Catalog[data-style="attorney-grid"]:after {
        content: "";
        display: block;
        clear: both;
    }
}

.Catalog__Catalog[data-style="attorney-grid"] > li {
  margin-bottom: var(--space-double);
}
@media (--viewport-small) {
    .Catalog__Catalog[data-style="attorney-grid"] > li {
        float: left;
        width: 50%;
        padding: var(--space);
        margin-bottom: unset;
    }
    .Catalog__Catalog[data-style="attorney-grid"] > li:last-child {
        height: auto;
    }
}

@media (--viewport-menu) {
    .Catalog__Catalog[data-style="attorney-grid"] .Attorney__Attorney {
        color: white;
        position: relative;
        background-color: var(--color-sand);
        height: 30rem;
        min-height: 32rem;
        width: 100%;
        display: block;
    }
    .Catalog__Catalog[data-style="attorney-grid"] .Attorney__Attorney:before {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: linear-gradient(180deg, rgba(0,0,0, 0), rgba(0,0,0, 0.8), rgba(0,0,0, 0.8));
        background-size: 100% 0%;
        background-position: bottom center;
        background-repeat: no-repeat;
        transition: background-size var(--default-transition-speed);
        display: block;
        content: "";
        z-index: 5;
    }
    .Catalog__Catalog[data-style="attorney-grid"] .Attorney__Attorney:hover:before {
        background-size: 100% 200%;
    }
}

@media (--viewport-menu) {
    .Catalog__Catalog[data-style="attorney-grid"] .Attorney__Attorney .attorney-meta {
        position: absolute;
        top: 0px; left: 0px;
        width: 100%; height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;
        opacity: 0;
        transform: translateY(-3em);
        transition: opacity var(--default-transition-speed), transform var(--default-transition-speed);
    }
    .Catalog__Catalog[data-style="attorney-grid"] .Attorney__Attorney:hover .attorney-meta {
        opacity: 1;
        transform: translateY(0em);
    }
}

.Attorney__Attorney[data-type="attorney_list"] .Photo__Photo {
    margin-bottom: var(--space-half);
    position: relative;
    z-index: 1;
}
@media (--viewport-menu) {
    .Attorney__Attorney[data-type="attorney_list"] .Photo__Photo {
        width: 100%;
        height: 100%;
        margin: 0px;
        display: block;
        object-fit: cover;
        object-position: top;
        font-family: 'object-fit: cover; object-position: top;'
    }
}

@media (--viewport-menu) {
    [data-style="attorney-grid"] [itemprop="name"] {
        color: currentColor;
    }
}

@media (--viewport-menu) {
    [data-style="attorney-grid"] .Attorney__Attorney .Link__Clickthrough,
    [data-style="attorney-grid"] .Attorney__Attorney .Link__Clickthrough .icon {
        color: white;
    }

    [data-style="attorney-grid"] .Attorney__Attorney .Link__Clickthrough {
        border: var(--default-border-width) solid var(--color-gold);
        padding: 0.5em 1em;
        margin-top: var(--space-half);
        font-size: var(--type-size--1);
    }
}

.Catalog__Catalog[data-style="attorney-grid"] .staff_list,
.Catalog__Catalog[data-style="attorney-grid"] .staff_list ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.Catalog__Catalog[data-style="attorney-grid"] .staff_list {
    margin-top: var(--space-triple);
}
@media (--viewport-menu) {
    .Catalog__Catalog[data-style="attorney-grid"] .staff_list {
        padding: var(--space);
    }    
}

.Headline__Headline_level-3[data-parent="staff_list"] {
    border-bottom: 1px solid var(--color-gold);
    max-width: 80%;
    margin: 0 auto;
}

.Catalog__Catalog[data-style="attorney-grid"] .staff_description {
    font-size: var(--type-size--1);
    margin-bottom: var(--space-half);
}

.Headline__Headline_level-4[data-parent="staff_list"] {
    border-bottom: 1px solid var(--color-gold);
    padding-bottom: var(--space-third);
    margin-bottom: var(--space-half);
}

@media (--viewport-menu) {
    .Catalog__Catalog[data-style="attorney-grid"] .staff_list .people {
        columns: 2;
    }
}

.Catalog__Catalog[data-style="attorney-grid"] .staff_list .name {
    font-size: 1em;
    display: block;
    line-height: 1;
    font-weight: 800;
}

.Catalog__Catalog[data-style="attorney-grid"] .staff_list .position {
    font-size: 0.75em;
    font-style: italic;
    line-height: 1;
}

.Catalog__Catalog[data-style="attorney-grid"] .person {
    margin-bottom: var(--space-half);
    break-inside: avoid;
}

.Catalog__Catalog[data-style="attorney-grid"] .area {
    margin-bottom: calc(var(--space) * 1.5);
}