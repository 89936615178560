[class*='Navigation__'] ul {
    padding: 0px;
    margin: 0px;
}
@media (--viewport-menu) {
    [class*='Navigation__'] ul {
        height: 100%;
    }
}

[class*='Navigation__Item__'] {
    width: 100%;
}
@media (--viewport-menu) {
    [class*='Navigation__Item__'] {
        width: auto;
        height: 100%;
    }
}

.Link__Navigation {
    font-weight: bold;
    padding: 1em;
    padding-bottom: 1em;
    display: block;
    height: 100%;
    color: var(--color-gold);
}
@media (--viewport-menu) {
    .Link__Navigation {
        padding: 0.25em 1em;        
    }
}
@media (--viewport-menu) {
    .active > .Link__Navigation {
        box-shadow: inset 0px -0.333em 0px 0px var(--color-gold);
        color: var(--color-gold);
    }
}