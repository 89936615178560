[data-type="case-masthead"] .Link__Clickthrough {
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
}

[data-type="case-masthead"] .Link__Clickthrough .icon {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    transform: rotate(180deg);
}