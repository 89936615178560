@media (--viewport-menu) {
  body.post-type-archive-faq .Masthead__Page {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}

.faq_list {
  padding-top: 0px;
}

.faq_list .Questions__Questions {
  text-align: left;
  margin-bottom: var(--space-double);
}

.faq_list .Questions__Questions > * {
  max-width: 50rem;
  margin: auto;
}

.faq_list .Headline__Headline_level-2[data-parent="Questions__Questions"] {
  padding: 0px var(--space);
  font-size: var(--type-size-2);
}

.faq_list .Catalog__Reveal[data-parent=Questions__Questions] dt {
  font-size: var(--type-size-1);
  margin-top: var(--space);
}

.faq_list .Catalog__Reveal[data-parent=Questions__Questions] dd {
  padding: 0px var(--space);
}

.faq_list .Separator[data-parent="Questions__Questions"] {
  margin-top: var(--space-double);
  max-width: 100%;
}