[class*='Link__Slab'] {
    text-decoration: none;
    color: var(--color-gold);
    border: var(--heavy-border-width) solid var(--color-gold);
    background: linear-gradient(to top, rgba(var(--color-gold-base),1) 0%, rgba(var(--color-gold-base),1) 50%, rgba(var(--color-gold-base),0) 50%, rgba(var(--color-gold-base),0) 100%);
    background-size: 100% 200%;
    background-position: 0% 0%;
    padding: 0.75em 1.25em;
    position: relative;
    display: inline-block;
    overflow: hidden;
    will-change: color;
    transition: color var(--default-transition-speed), background-position var(--default-transition-speed);
}
[class*='Link__Slab']:hover {
    color: white;
    background-position: 0% 100%;
}