.Bucket__Post {    
    margin-bottom: var(--space-double);
}

.Photo__Photo[data-parent="Bucket__Post"] {
    display: block;
}

.Headline__Headline_level-3[data-parent="Bucket__Post"] {
    line-height: 1.5;
    font-size: var(--type-size-1);
    margin-top: var(--space-quarter);
    text-transform: capitalize;
}

.Bucket__Post .post_meta {
    font-size: var(--type-size--2);
    font-family: var(--type-face-mechanical);
    text-transform: uppercase;
    letter-spacing: 0.075em;
}

.Bucket__Post .post_meta > * {
    display: inline-block;
}

.Bucket__Post .post_meta .date time {
    color: var(--color-gold);
}

.Bucket__Post .post_meta .date:before {
    display: inline-block;
    content: "|";
    padding-right: 0.25rem;
}

.Catalog__Catalog[data-parent="Bucket__Post"] {
    display: inline;
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.Catalog__Catalog[data-parent="Bucket__Post"] li {
    display: inline-block;
}
.Catalog__Catalog[data-parent="Bucket__Post"] li:after {
    content: ",";
}
.Catalog__Catalog[data-parent="Bucket__Post"] li:last-child:after {
    display: none;
}