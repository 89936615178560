.Masthead__Page {
    display: flex;
    flex-direction: column;
    background-color: var(--color-navy);
    color: white;
    position: relative;
}
@media (--viewport-small) {
    .Masthead__Page {
        display: block;
        background-color: transparent;
        color: unset;
        padding-top: var(--space-double)
    }
}

.Headline__Microtype[data-parent="Masthead__Page"] {
    order: 2;
    margin-top: var(--space);
}
@media (--viewport-small) {
    .Headline__Microtype[data-parent="Masthead__Page"] {
        margin-top: 0px;
    }
}

.Headline__Headline_level-1[data-parent="Masthead__Page"] {
    line-height: 1.25;
    order: 3;
    text-transform: capitalize;
}

.Text__Text[data-parent="Masthead__Page"] {
    order: 5;
    padding-top: var(--space-half);        
}

.Text__Text[data-parent="Masthead__Page"] p {
    margin: 0px;
    margin-top: var(--space-half);
}

.Separator[data-parent="Masthead__Page"] {
    order: 4;
    margin: 0px;
    margin-top: var(--space);
}
@media (--viewport-small) {
    .Separator[data-parent="Masthead__Page"] {
        margin: var(--space-double) 0px;
    }
}

.Photo__Photo[data-parent="Masthead__Page"] {
    display: block;
    margin: -var(--space);
    margin-bottom: 0px;
    width: calc(100% + var(--space-double));
    height: auto;
    max-width: none;
    order: 1;
}
@media (--viewport-small) {
    .Photo__Photo[data-parent="Masthead__Page"] {
        margin: 0 auto;
        width: unset;
        max-width: 100%;
    }
}