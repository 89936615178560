.Attorney__Attorney .Photo__Photo {
    margin-bottom: var(--space);
}

.Attorney__Attorney [itemprop="name"] {
    color: var(--color-gold);
    font-size: var(--type-size-2);
    line-height: 1;
}
@media (--viewport-menu) {
    .Attorney__Attorney [itemprop="name"] {
        font-size: var(--type-size-3);
    }
}

.Attorney__Attorney [itemprop="jobTitle"] {
    font-size: var(--type-size-1);
}

.Attorney__Attorney .Link__Clickthrough {
    font-size: var(--type-size--1);
}