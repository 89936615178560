[class*='Catalog__'] {
    padding: 0px;
    margin: 0px;
}

dd[class*='Catalog__'] {
    margin: 0px;
}

.Catalog__Catalog[data-style="bulk_list"] {
    list-style: none;
    text-align: center;
}
@media (--viewport-small) {
    .Catalog__Catalog[data-style="bulk_list"] {
        columns: 2;        
    }
}
@media (--viewport-menu) {
    .Catalog__Catalog[data-style="bulk_list"] {
        columns: 3;
        padding-left: var(--space-double); padding-right: var(--space-double);
    }
}

.Catalog__Catalog[data-style="bulk_list"] li {
    position: relative;
    margin-left: var(--space-half);
    padding-bottom: var(--space-third);
    text-align: left;
}
.Catalog__Catalog[data-style="bulk_list"] li:before {
    content: "»";
    position: absolute;
    left: -var(--space-half);
}


.Catalog__Catalog[data-style="dropdown"] {
    list-style: none;
    width: 15rem;
    max-width: 100%;
    font-family: var(--type-face-mechanical);
    text-transform: uppercase;
    color: var(--color-black);
    font-size: var(--type-size--1);
    border: var(--color-gold) solid 1px;
    background-color: white;
    z-index: var(--z-surface);
}

.Catalog__Catalog[data-style="dropdown"] li {
    display: none;
    width: 100% !important;
    padding: var(--space-third);
    text-align: left;
    letter-spacing: 0.1em;
}
.Catalog__Catalog[data-style="dropdown"] li:first-child {
    display: block;
}
.Catalog__Catalog[data-style="dropdown"] li:first-child:after {
    content: "▾";
    color: var(--color-gold);
    display: inline-block;
    float: right;
    transform: rotate(0deg);
    transition: var(--default-transition-speed) transform;
}
.Catalog__Catalog[data-style="dropdown"] li.open:first-child:after {
    transform: rotate(180deg);
}

.Catalog__Catalog[data-style="dropdown"] .Link__Inline {
  color: var(--color-black);
}

.dropdown_wrapper {
  width: 100%;
}
@media (--viewport-menu) {
  .dropdown_wrapper {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
  }
}

.dropdown_wrapper_container {
  width: 100%;
}
@media (--viewport-menu) {
  .dropdown_wrapper_container {
    padding-top: 6rem;
    position: relative;
  }
}