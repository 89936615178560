/* simple, one-line element definitions */

.Separator { /* use on `hr` */
    border: currentColor solid;
    border-width: 1px 0px;
    height: .5em;
}

.clearfix:after {
    display: block;
    content: "";
    clear: both;
}

/* Breakpoint Visibility
 * Display things only on particularly viewport sizes.
 */

@media (--viewport-menu-inverse) {
    [data-display-on="desktop"] { /* appears only on desktop */
        display: none !important;
    }
}

@media (--viewport-menu) {
    [data-display-on="mobile"] { /* appears only on mobile */
        display: none !important;       
    }
}

/* Block Types
 * Define types of blocks for re-use.
 */

[data-body-block-type='basic'] {
    width: var(--default-content-width);
    max-width: 100%;
    margin: 0px auto var(--space-double) auto;
    padding: var(--space);
    text-align: left;
    position: relative;
}
@media (--viewport-menu) {
    [data-body-block-type='basic'] {
        text-align: center;        
    }
}

[data-body-block-type='basic'] > [class*='Headline__'] {
    width: var(--default-text-width);
    max-width: 100%;
    margin-left: 0px;
    line-height: 1.25;
}
@media (--viewport-menu) {
    [data-body-block-type='basic'] > [class*='Headline__'] {
        margin-left: auto; margin-right: auto;
    }    
}

[data-body-block-type='basic'] > [class*='Text__'] {
    width: var(--default-text-width);
    max-width: 100%;
    margin-left: 0px; 
}
@media (--viewport-menu) {
    [data-body-block-type='basic'] > [class*='Text__'] {
        margin-left: auto; margin-right: auto;
    }
}

[data-body-block-type='full'] {
    width: 100%;
    max-width: 100%;
}

/* Simple Grid
 * Used for lists of articles.
 */

.article_grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.article_grid:after {
    display: block;
    content: "";
    clear: both;
}

.article_grid article {
    display: block;
    width: 100%;
    margin-bottom: var(--space);
}
@media (--viewport-small) {
    .article_grid article {
        width: calc(100% / 2);
        float: left;
        padding: var(--space-half);
    }
}
@media (--viewport-menu) {
    .article_grid article {
        width: calc(100% / 3);
    }
}