.Navigation__Primary {
    position: relative;
    flex: 1 1 0vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    will-change: flex;
    transition: flex var(--default-transition-speed), height var(--default-transition-speed);
    background-color: var(--color-navy);
    color: white;
}
.open > .Navigation__Primary {
    flex: 1 1 100vh;
    z-index: var(--z-atmosphere);
}
@media (--viewport-menu) {
    .Navigation__Primary {
        height: var(--desktop-menu-height);
        text-align: center;
        display: block;
        overflow: visible;
        box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
        z-index: var(--z-atmosphere);
    }    
}

ul.Navigation__Menu__Primary {
    overflow-y: auto;
    overflow-x: hidden;
    order: 3;
    position: relative;
    flex: 1 1 auto;
}
@media (--viewport-menu) {
    ul.Navigation__Menu__Primary {
        overflow: visible;
        display: flex;
        justify-content: center
    }
}

.Navigation__Primary__MobileSearch {
    order: 1;
}

.Navigation__Primary__Contact {
    order: 2;
}

.Navigation__Primary__Consult {
    order: 4;
}

.Navigation__Primary__Block__Contact {
    padding: 1em;
    color: white;
    background-color: var(--color-navy);
    border-bottom: 1px solid white;
    text-align: center;
}
.Navigation__Primary__Block__Contact > a {
    font-size: 0.85em;
}

.Navigation__Primary__Block__Contact [data-parent="Block"].Icon__Icon {
    color: var(--color-gold);
    margin-right: var(--space-quarter);
}

.Navigation__Primary__Block__Consult {
    font-size: 0.75em;
    padding: var(--space);
    border-top: 1px solid white;
}

.Navigation__Primary__Block__Consult .consult-phone {
    color: white;
}

.Navigation__Primary__Block__Consult .consult-arc {
    color: var(--color-gold);
}

.Navigation__Primary__Block__Consult .Block__Consult_phone {
    color: white;
}
.Navigation__Primary__Block__Consult .Block__Consult_phone:before,
.Navigation__Primary__Block__Consult .Block__Consult_phone:after {
    background-color: var(--color-gold);
}