.resources-wrapper {
    text-align: center;
    width: var(--wide-content-width);
}

.resource_post-sort {
    display: inline-block;
}

.resource_links-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.Bucket__Bucket[data-parent="resource_links-wrapper"] {
    flex: 0 0 100%;
    max-width: 100%;
    display: inline-block;
    padding: var(--space-half);
    margin-bottom: var(--space-half);
}
@media (--viewport-small) {
  .Bucket__Bucket[data-parent="resource_links-wrapper"] {
    flex: 0 0 calc(100% / 2);
  }
}
@media (--viewport-menu) {
  .Bucket__Bucket[data-parent="resource_links-wrapper"] {
    flex: 0 0 calc(100% / 3);
  }
}
@media (--viewport-desktop) {
  .Bucket__Bucket[data-parent="resource_links-wrapper"] {
    flex: 0 0 calc(100% / 4);
  }
}

.Bucket__Bucket[data-parent="resource_links-wrapper"] .Link__Clickthrough {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding-right: 2em;
}
.Bucket__Bucket[data-parent="resource_links-wrapper"] .Link__Clickthrough:after {
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, white 60%, white 100%);
  content: "";
  position: absolute;
  height: 100%;
  width: 2.5em;
  right: 0px;
  top: 0px;
  z-index: var(--z-surface);
}

.Bucket__Bucket[data-parent="resource_links-wrapper"] .Link__Clickthrough .icon {
  text-align: right;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  z-index: var(--z-atmosphere);
}

.Bucket__Bucket[data-parent="resource_links-wrapper"] .Headline__Headline_level-4 {
    font-size: var(--type-size-1);
    border-bottom: 0px;
    margin-bottom: 0px;
}

.Bucket__Bucket[data-parent="resource_links-wrapper"] .Link__Clickthrough {
  font-size: var(--type-size--2);
}