.Separator[data-parent="about_intro"] {
    margin-top: var(--space);
}

/* partners */

.about_partners {
    background-color: var(--color-denim);
    color: white;
    padding: var(--space-quad) 0px;
    margin-bottom: var(--space-double);
}

.Masthead__Masthead[data-parent="about_partners"] {
    margin-bottom: 0px;
}

.about_partners .Link__Clickthrough {
    color: white;
}

.about_partners .Link__Clickthrough .icon {
    color: white;
}


/* attorneys */

.about_attorneys {
    text-align: center;
}

.about_attorneys .Catalog__Catalog[data-style="attorney-list"] {
    width: var(--wide-content-width);
    margin-left: auto; margin-right: auto;
    max-width: 100%;
    vertical-align: top;
    text-align: center;
}

@media (--viewport-small) {
    .about_attorneys .Catalog__Catalog[data-style="attorney-list"] > li {
        width: calc(var(--wide-content-width) / 4.25);
        display: inline-block;
        vertical-align: top;
        margin-bottom: var(--space);
    }
}

.about_attorneys .Attorney__Attorney {
    margin-bottom: var(--space-double);
}

.about_attorneys .Attorney__Attorney [itemprop=jobTitle] {
      color: var(--color-black);
  }

@media (--viewport-menu) {
    .about_attorneys .Attorney__Attorney [itemprop=name] {
        font-size: var(--type-size-1);
    }
    .about_attorneys .Attorney__Attorney [itemprop=jobTitle] {
        font-size: calc(var(--type-size-1) * 0.75);
    }
}

.about_attorneys .Attorney__Attorney .Photo__Photo {
    margin-bottom: var(--space-half);
}

.Link__Slab[data-parent="about_attorneys"] {
    font-size: var(--type-size--1);
    margin: var(--space-double);
}


/* testimonials */

.about_testimonials {
    text-align: center;
}

.Masthead__Masthead[data-parent="about_testimonials"] {
    margin-bottom: var(--space);
    text-align: center;
}
.Masthead__Masthead[data-parent="about_testimonials"] > * {
  margin: auto;
}

.about_testimonials .Link__Clickthrough {
    margin: var(--space) 0px;
}

.Catalog__Catalog[data-parent="about_testimonials"] {
    list-style: none;
    padding: 0px; margin: 0px;
    color: white;
}

/* espanol */

.about_spanish {
    background-color: var(--color-sand);
    padding: var(--space-double) 0px;
}

.Masthead__Masthead[data-parent="about_spanish"] {
    margin-bottom: 0px;
}