/* Mobile */
.Navigation__Sub__Primary {
    position: absolute;
    top: 0px;
    left: 0px;
    list-style: none;
    margin: 0px;
    padding: 0px;
    padding-bottom: var(--space);
    transform: translateX(100%);
    transition: transform var(--default-transition-speed);
    z-index: var(--z-atmosphere);
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--color-navy);
    color: white;
    display: flex;
    flex-direction: column;
}
.submenu_toggle_checkbox:checked ~ .Navigation__Sub__Primary {
    transform: translateX(0%);
}

.submenu_toggle_checkbox {
    display: none;
}

.submenu_toggle {
    padding: 1em;
}

ul.activated {
  overflow: hidden;
  flex: 0 1 0%;
}

.Navigation__Menu__Primary.activated {
  flex: 1 1 auto;
}

@media (--viewport-menu) {
    .Navigation__Primary .submenu_toggle_checkbox,
    .Navigation__Primary .submenu_toggle {
        display: none;
    }
}

@media (--viewport-menu) {
    .Navigation__Sub__Primary {
        display: none;
    }
}