body.en-espanol .Text__Corpus {
    display: flex;
    flex-wrap: wrap;
    width: calc(var(--wide-content-width) * 1.1);
}

body.en-espanol .Text__Corpus > * {
    flex: 1 1 100%;
}

body.en-espanol .Text__Corpus .spanish-1,
body.en-espanol .Text__Corpus .spanish-2,
body.en-espanol .Text__Corpus .spanish-3 {
    flex: 1 1 100%;
    padding: var(--space);
}
@media (--viewport-small) {
    body.en-espanol .Text__Corpus .spanish-1,
    body.en-espanol .Text__Corpus .spanish-2,
    body.en-espanol .Text__Corpus .spanish-3 {
        flex: 1 1 calc((100% / 2) - var(--space) );
        padding: var(--space);
    }
}
@media (--viewport-desktop) {
    body.en-espanol .Text__Corpus .spanish-1,
    body.en-espanol .Text__Corpus .spanish-2,
    body.en-espanol .Text__Corpus .spanish-3 {
        flex: 1 1 calc((100% / 3) - var(--space-double) );
        padding: var(--space);
    }
}

body.en-espanol .Text__Corpus .spanish-1 {
    background-color: var(--color-denim);
    color: white;
}

body.en-espanol .Text__Corpus .spanish-2 {
  background-color: var(--color-sand);
}
@media (--viewport-small) {
  body.en-espanol .Text__Corpus .spanish-2 {
      margin-left: var(--space);
  }
}
@media (--viewport-menu) {
    body.en-espanol .Text__Corpus .spanish-2 {
        margin: 0px var(--space);
        margin-right: 0px;
    }
}
@media (--viewport-desktop) {
  body.en-espanol .Text__Corpus .spanish-2 {
    margin-right: var(--space);
  }
}

body.en-espanol .Text__Corpus .spanish-3 {
    background-color: var(--color-navy);
    color: white;
}