.Masthead__Featured {
  /*margin-top: var(--space-double);*/
}

.Separator[data-parent="Masthead__Featured"] {
  margin: var(--space) auto;
}

.Headline__Headline_level-1[data-parent="Masthead__Featured"] {
  font-size: var(--type-size-2);
}

/*.Photo__Photo[data-parent="Masthead__Featured"] {
  display: block;
  margin: var(--space) auto;
}*/
.Photo__Photo[data-parent="Masthead__Featured"] {
    display: block;
    margin: var(--space) auto;
    width: 100vw;
    max-width: 100%;
    height: auto;
    order: 1;
}
@media (--viewport-small) {
    .Photo__Photo[data-parent="Masthead__Featured"] {
        width: 50rem;
        max-width: 100%;
    }
}

.Link__Clickthrough[data-parent="Masthead__Featured"] {
  display: inline-block;
}

.Masthead__Featured .taxonomies {
  font-size: var(--type-size--2);
  font-family: var(--type-face-mechanical);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.Catalog__Catalog[data-parent="Masthead__Featured"] {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: inline-block;
}

.Catalog__Catalog[data-parent="Masthead__Featured"] li {
  display: inline-block;
}
.Catalog__Catalog[data-parent="Masthead__Featured"] li:after {
  content: ",";
}
.Catalog__Catalog[data-parent="Masthead__Featured"] li:last-child:after {
  content: "";
}