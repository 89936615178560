:root {
  /* typeface */
  --type-face-headline: "Hoefler Text A", "Hoefler Text B", TimesNewRoman,"Times New Roman", Times, Baskerville, Georgia, serif;
  --type-face-body: 'Droid Serif', "Lucida Bright", Georgia, serif;
  --type-face-mechanical: "Gotham A", "Gotham B", 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;

    /* Type Size */
    /* The commented values reflect the pt size used in the Illustrator 
        mock-up */
    --type-size-base: 1rem; /* 18 pt */
    --type-size--3:  calc( var(--type-size-0) * 0.55556 ); /* 10pt */
    --type-size--2:  calc( var(--type-size-0) * 0.66667 ); /* 12pt */
    --type-size--1:  calc( var(--type-size-0) * 0.77778 ); /* 14pt */
    --type-size-0:   var(--type-size-base); /* 18 pt */
    --type-size-1:   calc( var(--type-size-0) * 1.33333 );  /* 24 pt */
    --type-size-2:   calc( var(--type-size-0) * 2.11111 ); /* 38 pt */
    --type-size-3:   calc( var(--type-size-0) * 3 ); /* 54 pt */
    --type-size-4:   calc( var(--type-size-0) * 3.61111 ); /* 64 pt */
    --type-size-5:   calc( var(--type-size-0) * 5.55556 ); /* 100 pt */

    /* Line Height */
    --type-height-base: 1.333rem;


  /* Sizes */
  --space-quarter: calc(var(--space) / 4);
  --space-third: calc(var(--space) / 3);
  --space-half: calc(var(--space) / 2);
  --space: 1.5rem;
  --space-double: calc(var(--space) * 2);
  --space-triple: calc(var(--space) * 3);
  --space-quad: calc(var(--space) * 4);
  --space-quint: calc(var(--space) * 5);

    /* sizes : widths */
    --default-border-width: 2px;
    --heavy-border-width: 4px;
    --default-content-width: 65.8888889rem; /* if you change this, change --viewport-content-width/-inverse ! */
    --wide-content-width: 78.88888889rem;
    --default-text-width: 44rem;

    /* sizes : heights */
    --desktop-menu-height: 3.25rem;
    --desktop-header-logo-height: 5rem;
    --mobile-header-logo-height: 2.5rem;


  /* colors */
    /* primary : gold */;
    --color-gold-base: 190, 167, 108;
    --color-gold: rgb(var(--color-gold-base));
    
    /* secondary : red */
    --color-red-base: 164, 42, 47;
    --color-red: rgb(var(--color-red-base));

    /* tertiary : navy */
    --color-navy-base: 54, 57, 62;
    --color-navy: rgb(var(--color-navy-base));

    /* quaternary : denim */;
    --color-denim-base: 75, 106, 115;
    --color-denim: rgb(var(--color-denim-base));

    /* beige : quinary */
    --color-beige-base: 216, 213, 206;
    --color-beige: rgb(var(--color-beige-base));

    /* sand : senary */
    --color-sand-base: 244, 241, 239;
    --color-sand: rgb(var(--color-sand-base));

    /* "black" */
    --color-black: var(--color-navy);

  /* speed */
  --default-transition-speed: 0.3s;
  --fast-transition-speed: 0.15s;

  /* Layers */
  --z--core: -20;
  --z--mantle: -10;
  --z-crust: 0;
  --z-surface: 10;
  --z-atmosphere: 20;
  --z-orbit: 30;
}

@custom-media --viewport-mini (width >= 420px);
@custom-media --viewport-mini-inverse (width < 422px);
@custom-media --viewport-small (width >= 768px);
@custom-media --viewport-small-inverse (width < 768px);
@custom-media --viewport-menu (width >= 1024px);
@custom-media --viewport-menu-inverse (width < 1024px);
@custom-media --viewport-medium (width >= 1024px);
@custom-media --viewport-medium-inverse (width < 1024px);
@custom-media --viewport-content-width (width < 65.8888889rem); /* MAGIC NUMBER if --default-content-width changes, change this! */
@custom-media --viewport-content-width-inverse (width >= 65.8888889rem); /* MAGIC NUMBER if --default-content-width changes, change this! */
@custom-media --viewport-desktop (width >= 1366px);
@custom-media --viewport-normal (width >= 1366px);
@custom-media --viewport-large (width >= 1920px);
@custom-media --viewport-huge (width >= 1920px);

@media (--viewport-mini-inverse) {}
@media (--viewport-small-inverse) {}
@media (--viewport-medium-inverse) {}
@media (--viewport-mini) {}
@media (--viewport-small) {}
@media (--viewport-menu) {}
@media (--viewport-medium) {}
@media (--viewport-desktop) {}
@media (--viewport-large) {}
@media (--viewport-huge) {}