.Block__Consult {
    text-align: center;
    margin: 0px auto;
    color: var(--color-red);
}

.Block__Consult_arc {
    display: block;
    margin: 0px auto;
}

.Block__Consult_phone {
    text-decoration: none;
    font-weight: bold;
    font-family: var(--type-face-mechanical);
    position: relative;
    display: inline-block;
}
.Block__Consult_phone:before,
.Block__Consult_phone:after {
    display: block;
    content: "";
    position: absolute;
    width: 1.75rem;
    height: 2px;
    top: 50%;
    background-color: var(--color-red);
    transform: translateY(-50%);
}
.Block__Consult_phone:before {
    left: -2.25rem;
}
.Block__Consult_phone:after {
    right: -2.25rem;
}