.Link__Clickthrough {
    text-transform: uppercase;
    color: var(--color-navy);
    font-family: var(--type-face-mechanical);
    display: inline-flex;
    align-items: center;
    font-size: var(--type-size--2);
    font-weight: 800;
    letter-spacing: 0.08em;
    line-height: 1;
}

.Link__Clickthrough .icon {
    color: var(--color-gold);
    vertical-align: text-bottom;
    margin-left: 0.333em;
}