.Bucket__Testimonial {
    padding: var(--space);
    color: white;
}

.Headline__Headline_level-5[data-parent="Bucket__Testimonial"] {
    font-size: var(--type-size-0);
    font-style: normal;
}

.Text__Text[data-parent="Bucket__Testimonial"] {
    font-size: var(--type-size--1);
    font-style: italic;
}

.Bucket__Testimonial .source {
    font-size: var(--type-size--1);
}
.Bucket__Testimonial .source:before {
    content: " - ";
}

.Catalog__Catalog[data-parent="Bucket__Testimonial"] {
    list-style: none;
    font-size: var(--type-size--3);
    margin-top: var(--space);
}

.Catalog__Catalog[data-parent="Bucket__Testimonial"] li {
    display: inline-block;
    margin-right: var(--space-half);
    margin-bottom: 0.75em;
    float: none !important;
}
.Catalog__Catalog[data-parent="Bucket__Testimonial"] li:last-child {
    margin-right: 0px;
}

.Catalog__Catalog[data-parent="Bucket__Testimonial"] li a {
    display: block;
}