[class*='Headline__'] {
    font-family: var(--type-face-headline);
    font-weight: 800;
    margin: 0px;
}

.Headline__Headline_level-1 {
    font-size: var(--type-size-2);
}
@media (--viewport-menu) {
    .Headline__Headline_level-1 {
        font-size: var(--type-size-3);
    }
}

.Headline__Headline_level-2 {
    font-size: var(--type-size-2);
}
@media (--viewport-menu) {
  .Headline__Headline_level-2 {
      font-size: var(--type-size-3);
  }
}

.Headline__Headline_level-3 {
    font-size: var(--type-size-2);
}

.Headline__Headline_level-4 {
    font-size: var(--type-size-1);
}