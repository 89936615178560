.Bucket__LCase {}

.Headline__Headline_level-4[data-parent="Bucket__LCase"] {
    font-size: var(--type-size-1);
}

.Headline__Headline_level-4[data-parent="Bucket__LCase"] .year {
    font-size: calc(var(--type-size-0) * 0.8888889);
}
.Headline__Headline_level-4[data-parent="Bucket__LCase"] .year:before {
    content: "(";
}
.Headline__Headline_level-4[data-parent="Bucket__LCase"] .year:after {
    content: ")";
}

.Bucket__LCase .taxonomies {
    font-family: var(--type-face-mechanical);
    font-size: var(--type-size--1);
}

.Catalog__Catalog[data-parent="Bucket__LCase"] {
    list-style: none;
    display: inline-block;
}

.Catalog__Catalog[data-parent="Bucket__LCase"] li {
    display: inline-block;
}
.Catalog__Catalog[data-parent="Bucket__LCase"] li:after {
    content: " | ";
}
.Catalog__Catalog[data-parent="Bucket__LCase"] li:last-child:after {
    content: "";
}

.Text__Text[data-parent="Bucket__LCase"] {
    margin-top: var(--space-third);
    margin-bottom: var(--space-half);
}