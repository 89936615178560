body.error404 {
  background-color: var(--color-sand);
}

body.error404 .site__main {
  max-width: 70rem;
  margin: 0px auto;
  margin-top: var(--space-double);
}
@media (--viewport-menu) {
  body.error404 .site__main {
    display: flex;    
  }
}

.warning_404_image {
  display: block;
  order: 1;
}

.wrapper-404 {
  padding: var(--space);
}
@media (--viewport-menu) {
  .wrapper-404 {
    order: 2;
    margin-top: 8rem;
  }
}

.wrapper-404 .Headline__Headline_level-1[data-parent="404"] {
  font-size: var(--type-size-1);
  color: var(--color-gold);
  font-family: var(--type-face-mechanical);
  text-transform: uppercase;
}

.error_page_title {
  font-size: var(--type-size-3);
  line-height: 1.25;
  font-weight: 800;
}

.error_page_title + p {
  margin-top: 0px;
}