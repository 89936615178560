[class*="Bucket__"] {
    text-align: left;
}

[class*='Headline__Headline'][data-parent="Bucket__Bucket"] {
    border-bottom: var(--default-border-width) solid var(--color-gold);
    margin-bottom: var(--space-half);
}

.Text__Text[data-parent="Bucket__Bucket"] {
    font-size: var(--type-size-0);
}

/* Sizes */

[data-bucket-size="bucket_medium"] {
    width: 100%;
}
@media (--viewport-small) {
    [data-bucket-size="bucket_medium"] {
        width: calc(100% / 2);
    }
}
@media (--viewport-menu) {
    [data-bucket-size="bucket_medium"] {
        width: calc(100% / 3);
    }
}