[class*="Block__"] a {
    text-decoration: none;
    font-weight: bold;
    color: currentColor;
}

[data-parent="Block"].Icon__Icon {
    height: 1em;
    width: 1.25em;
    color: var(--color-navy);
    vertical-align: text-bottom;
}