.Bucket__Video .responsive-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.Bucket__Video .responsive-video-wrapper iframe,  
.Bucket__Video .responsive-video-wrapper object,  
.Bucket__Video .responsive-video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Headline__Headline_level-3[data-parent="Bucket__Video"] {
    font-size: var(--type-size-1);
}