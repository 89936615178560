[data-parent='homepage'][data-body-block-type='basic'] {
    padding-top: var(--space-triple);
    padding-bottom: 0px;
}

[data-parent='homepage'][data-body-block-type='basic'] .Headline__Headline_level-2 {
    padding-bottom: var(--space-double);
}

/* practice areas */

.home_practice-areas {
    padding: var(--space);
}

@media (--viewport-small) {
    .home_practice-areas .area_wrapper {
        margin-right: -var(--space);
        display: flex;
    }
}

.Bucket__FrontPractice[data-parent="home_practice-areas"] {
    margin-bottom: var(--space-double)
}
@media (--viewport-small) {
    .Bucket__FrontPractice[data-parent="home_practice-areas"] {
        padding-right: var(--space);
        float: left;
        position: relative;
        padding-bottom: 1.5em;
    }
}

@media (--viewport-small) {
  .Bucket__FrontPractice[data-parent="home_practice-areas"] .Link__Clickthrough {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}

.Bucket__FrontPractice[data-parent="home_practice-areas"] .Photo__Photo {
    width: 100%;
    height: auto;
}

/* featured blog posts */

.home_blog-posts {
    padding: var(--space);
}

.home_blog-posts .Headline__Headline_level-3 {
    font-size: var(--type-size-1);
}

.Bucket__Post[data-parent="home_practice-areas"] {
    margin-bottom: 3rem;
}

/* ebooks */
.home_ebooks[data-parent='homepage'] {
    padding-top: var(--space-triple);
    background-color: var(--color-sand);
}
@media (--viewport-menu) {
    .home_ebooks[data-parent='homepage'] {
        background-color: unset;
    }
}

.home_ebooks[data-parent='homepage'] .Headline__Headline_level-2 {
    padding-bottom: var(--space-double);
}

@media (--viewport-menu) {
    .Ebook__Ebook[data-parent="homepage"] {
        margin-bottom: var(--space);
    }
}