.Headline__Headline_level-3[data-type="author_blog_title"] {
    margin: var(--space-double) var(--space) 0px;
    text-align: left;
}
@media (--viewport-menu) {
  .Headline__Headline_level-3[data-type="author_blog_title"] {
    text-align: center;
  }
}

body.author .Separator.masthead-bottom-sep {
  margin-top: var(--space);
  max-width: 100%;
}