.site__header {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: var(--color-sand);
}
@media (--viewport-menu) {
    .site__header {
        position: relative;
        display: block;
    }
}

.site__header__cap {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
}
@media (--viewport-menu) {
    .site__header__cap {
        padding: calc(var(--space) * 1.5) var(--space);
        justify-content: center;
    }
}

.Button__Button_site__header {
    padding: var(--space);
    background-color: var(--color-navy);
    outline: none;
}

.SVG__Embed.mobile-toggle {
    transform: translateX(0px) rotate(180deg);
    will-change: transform;
    transition: transform var(--default-transition-speed);
}
.site__header.open .SVG__Embed.mobile-toggle {
    transform: translateX(-2px) rotate(180deg);
}

.SVG__Embed.mobile-toggle svg {
    background-color: transparent;
    stroke: var(--color-gold);
    display: block;
    width: 2em;
    height: 2em;
}

.SVG__Embed.mobile-toggle [class*='hamburger-line'] {
    stroke-width: 2px;
    transform-origin: 0%;
    will-change: transform, opacity;
    opacity: 1;
    transform: rotate(0deg) translateX(0%);
    transition: transform var(--default-transition-speed), opacity var(--default-transition-speed);
}

.site__header.open .SVG__Embed.mobile-toggle .hamburger-line-1 {
    transform: rotate(45deg);
}

.site__header.open .SVG__Embed.mobile-toggle .hamburger-line-2 {
    opacity: 0;
    transform: rotate(0deg) translateX(100%);
}

.site__header.open .SVG__Embed.mobile-toggle .hamburger-line-3 {
    transform: rotate(-45deg);
}

.site__header__Block__Contact {
    color: var(--color-gold);
    font-size: var(--type-size--1);
    margin-top: -1rem;
}

.site__header__Block__Consult svg {
    color: var(--color-navy);
}