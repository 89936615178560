.Logo__Header {
    width: auto;
    max-width: 60vw;
    display: block;
}
@media (--viewport-menu) {
    .Logo__Header {
        height: auto;
        width: var(--desktop-header-logo-height);
        max-width: auto;
        flex: 0 1 20rem;
        display: flex;
        align-items: center;
    }
}

.Logo__Header_link {
    padding: var(--space);
}
@media (--viewport-menu) {
    .Logo__Header_link {
        padding: 0px;
    }
}

.Logo__Header_link,
.Logo__Header_image {
    display: block;
}

.Logo__Header_image {
    height: var(--mobile-header-logo-height);
}
@media (--viewport-menu) {
    .Logo__Header_image {
        width: 100%;
        height: 100%;
    }
}