dt[class*='Catalog__Reveal_item'] {
    cursor: pointer;
    font-size: var(--type-size-2);
}

.Icon__Icon[data-parent='Catalog__Reveal'] {
    width: .75em;
    height: .75em;
    will-change: transform;
    transform: rotate(0deg);
    transition: transform var(--fast-transition-speed);
}
.revealed > .Icon__Icon[data-parent='Catalog__Reveal'] {
    transform: rotate(90deg);
}

dd[class*='Catalog__Reveal_item'] {
    display: none;
}