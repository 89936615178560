/* general */


/* header */

.Icon__Icon[data-parent="Masthead__Page"] {
    order: 6;
    align-self: flex-end;
    height: var(--space-double);
    width: var(--space-double);
    color: var(--color-gold);
}

.Link__Clickthrough[data-type="area-masthead"] {
    margin: var(--space);
    border: 1px solid var(--color-gold);
    border-width: 1px 0px;
    padding: 0.5em;
    padding-left: 1.5em;
    position: relative;
}
.Masthead__Page .Link__Clickthrough[data-type="area-masthead"][data-display-on="mobile"] {
    position: absolute;
    bottom: -var(--space-triple);
    left: 0px;
    border: 0px;
}

.Masthead__Page .Link__Clickthrough[data-type="area-masthead"][data-display-on="mobile"] .icon {
  top: 0.5em;
}

.Link__Clickthrough[data-type="area-masthead"] .icon {
    position: absolute;
    left: 0px;
    top: 0.5em;
    transform: rotate(180deg);
}


/* body */

@media (--viewport-menu) {
    .practice-area .Text__Corpus {
        columns: 2;
    }
}


/* faq */

.practice_faq {
    background-color: var(--color-black);
    color: white;
    padding: var(--space-double) var(--space);
}

.practice_faq > * {
    padding: var(--space-half) 0px;    
}
@media (--viewport-menu) {
    .practice_faq > * {
        max-width: var(--default-content-width);
        margin: 0 auto;
        padding: var(--space);    
    }
}

.practice_faq .Headline__Headline_level-3 {
    padding-bottom: 0px;
}

.practice_faq .Questions__Questions dt {
    font-size: var(--type-size-0);
    color: var(--color-gold);
    max-width: 40rem;
}

.practice_faq .Questions__Questions dd {
    font-size: var(--type-size--1);
    max-width: 40rem;
    padding: 0px var(--space);
    line-height: 1.5;
}

.practice_faq .Questions__Questions .Separator {
    display: none;
}

.practice_faq_link-wrapper .Link__Clickthrough {
    color: white;
}


/* get in touch */

.practice_get-in-touch {
    text-align: center;
}

.practice_get-in-touch .Separator {
  margin-top: var(--space-quad);
}

.Headline__Headline_level-1[data-parent="practice_get-in-touch"] {
    line-height: 1;
}
@media (--viewport-menu) {
    .Headline__Headline_level-1[data-parent="practice_get-in-touch"] {
        font-size: var(--type-size-5);
    }
}

.Link__Inline[data-parent="practice_get-in-touch"] {
    font-size: var(--type-size-1);
    display: inline-block;
    margin-bottom: var(--space-half);
}
@media (--viewport-menu) {
    .Link__Inline[data-parent="practice_get-in-touch"] {
        font-size: var(--type-size-2);
    }
}


/* related articles */

.practice_related-posts {
    text-align: center;
}

.practice_related-posts_header {
    margin-bottom: var(--space-half);
}

.Headline__Headline_level-3[data-parent="practice_related-posts"] {
    line-height: 1.25;
}

/* representative cases */

.practice_representative-cases {
    text-align: center;
}
.practice_representative-cases_header {
    margin-bottom: var(--space-half);
}

.Headline__Headline_level-3[data-parent="practice_representative-cases"] {
    line-height: 1.25;
}
.Bucket__LCase[data-parent="practice_representative-cases"] {
    margin-bottom: var(--space-double);
}

.Link__Slab[data-parent="practice_representative-cases"] {
    margin: 0px auto;
}


/* separator */

.Separator[data-parent="practice_representative-cases"] {
    margin-top: var(--space);
}


/* testimonials */

.practice_testimonials {
    text-align: center;
    margin-bottom: var(--space);
}

.practice_testimonials_header {
    margin-bottom: var(--space);
}

.Link__Clickthrough[data-parent="practice_testimonials"] {
    margin: var(--space) auto;
}