.Bucket__FrontPractice .photo-wrapper {
    position: relative;
    display: inline-block;
    margin: 0px;
    overflow: hidden;
}
.Bucket__FrontPractice .photo-wrapper:before {
    display: none;
}
@media (--viewport-menu) {
    .Bucket__FrontPractice .photo-wrapper:before {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: linear-gradient(180deg, rgba(var(--color-navy-base), 0), rgba(var(--color-navy-base), 0.8), rgba(var(--color-navy-base), 0.8));
        background-size: 100% 0%;
        background-position: bottom center;
        background-repeat: no-repeat;
        transition: background-size var(--default-transition-speed);
        display: block;
        content: "";
    }
}
.Bucket__FrontPractice .photo-wrapper:hover:before {
    background-size: 100% 200%;
}

.Bucket__FrontPractice .photo-wrapper figcaption {
    display: none;
}
@media (--viewport-menu) {
    .Bucket__FrontPractice .photo-wrapper figcaption {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        transform: translateY(-3em);
        transition: opacity var(--default-transition-speed), transform var(--default-transition-speed);
        color: white;
    }
}
.Bucket__FrontPractice .photo-wrapper:hover figcaption {
    opacity: 1;
    transform: translateY(0em);
}

.Photo__Photo[data-parent="Bucket__FrontPractice"] {
    display: block;
}

.Headline__Headline_level-4[data-parent="Bucket__FrontPractice"] {
    color: var(--color-black);
    border-bottom: var(--default-border-width) solid var(--color-gold);
    padding-bottom: var(--space-third);
    margin-bottom: var(--space-half);
}

.Bucket__FrontPractice .photo-wrapper figcaption {
    font-size: var(--type-size--2);
    font-family: var(--type-face-mechanical);
}

.Bucket__FrontPractice .photo-wrapper .Icon__Icon {
    height: 4rem;
    width: 5rem;
    margin-bottom: var(--space-half);
}