.Masthead__Text {
    background-color: var(--color-navy);
    color: white;
}

.Masthead__Text .inner_wrapper {
    max-width: var(--default-content-width);
    margin-left: auto; margin-right: auto;
    padding: var(--space-double) var(--space);
}
@media (--viewport-menu) {
  .Masthead__Text .inner_wrapper {
    padding: var(--space-quad) var(--space-double);
  }
}

.Headline__Microtype[data-parent="Masthead__Text"] {
    color: white;
}

.Headline__Headline_level-1[data-parent="Masthead__Text"] {
    color: var(--color-gold);
    line-height: 1.25;
}

.Headline__Headline_level-1[data-parent="Masthead__Text"] .year {
    font-size: var(--type-size-2);
}
.Headline__Headline_level-1[data-parent="Masthead__Text"] .year:before {
    content: "(";
}
.Headline__Headline_level-1[data-parent="Masthead__Text"] .year:after {
    content: ")";
}

.Catalog__Catalog[data-parent="Masthead__Text"] {
    list-style: none;
    margin-top: var(--space-third);
    margin-bottom: var(--space);
}

.Catalog__Catalog[data-parent="Masthead__Text"] li {
    font-size: var(--type-size--2);
    display: inline-block;
    margin-right: var(--space-third);
}
.Catalog__Catalog[data-parent="Masthead__Text"] li:last-child {
    margin-right: 0px;
}

.Masthead__Text .Link__Clickthrough {
    color: var(--color-gold);
    letter-spacing: 0.1em;
}

.Masthead__Text .Link__Clickthrough .icon {
    color: white;
}