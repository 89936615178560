.Headline__Headline_level-2[data-parent="videos-featured_list"] {
    margin-bottom: var(--space-half);
}

.Catalog__Catalog[data-parent="videos-featured_list"] {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
@media (--viewport-small) {
    .Catalog__Catalog[data-parent="videos-featured_list"] {
        display: flex;
        flex-wrap: wrap;
        margin-right: -var(--space);
    }
}
.Catalog__Catalog[data-parent="videos-featured_list"]:after {
    content: "";
    display: block;
    clear: both;
}

.Catalog__Catalog[data-parent="videos-featured_list"] li {
    margin-bottom: var(--space);
}
@media (--viewport-small) {
    .Catalog__Catalog[data-parent="videos-featured_list"] li {
        width: calc(100% / 2);
        float: left;
        padding-right: var(--space);
    }
}
@media (--viewport-menu) {
     .Catalog__Catalog[data-parent="videos-featured_list"] li {
        width: calc(100% / 4);
     }
}

.Link__Block[data-parent="videos-featured_list"] {
    display: inline-block;
    margin: 0px auto var(--space);
    font-size: var(--type-size--1);
}

/* archive */
.video_post-sort .Catalog__Catalog[data-style="dropdown"] {
    display: inline-block;
}