.upper {
    /* make this uppercase */
    text-transform: uppercase;
    font-family: var(--type-face-mechanical);
    letter-spacing: 0.1em;
}

@media (--viewport-menu-inverse) {
    .no-scroll-mobile {
        overflow: hidden;
    }
}

[data-justify='center'] {
    text-align: center;
}

.no-posts-message .rounded-search form {
  display: inline-flex;
}