.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-facebook {
  width: 0.587890625em;
}

.icon-linkedin {
  width: 0.857421875em;
}

.icon-twitter {
  width: 0.9287109375em;
}

.icon-catastrophic-injury {
  width: 2.0634765625em;
}

.icon-construction-accident {
  width: 0.9248046875em;
}

.icon-bike-accident {
  width: 1.71875em;
}

.icon-wrongful-death {
  width: 0.98828125em;
}

.icon-workers-compensation {
  width: 1.681640625em;
}

.icon-truck {
  width: 2.1923828125em;
}

.icon-spinal-cord-injury {
  width: 0.9091796875em;
}

.icon-social-security {
  width: 1.638671875em;
}

.icon-premises-liability {
  width: 0.806640625em;
}

.icon-personal-injury {
  width: 1.0078125em;
}

.icon-pedestrian {
  width: 0.6201171875em;
}

.icon-motorcycle-accident {
  width: 2.0234375em;
}

.icon-medical-malpractice {
  width: 0.98046875em;
}

.icon-disability {
  width: 1.23828125em;
}

.icon-defective-product {
  width: 0.58203125em;
}

.icon-car-accident {
  width: 3.03125em;
}

.icon-brain-injury {
  width: 0.927734375em;
}

.icon-left {
  width: 0.30859375em;
}

.icon-right {
  width: 0.30859375em;
}

.icon-envelope {
  width: 1.83984375em;
}

.icon-voice {
  width: 1.1611328125em;
}

.icon-path-caret-left {
  width: 0.5703125em;
}

.icon-open {
  width: 1.0234375em;
}

.icon-path-caret-right {
  width: 0.5703125em;
}

.icon-path-shield {
  width: 0.84765625em;
}

.icon-shield {
  width: 0.84765625em;
}

.icon-caret {
  width: 0.6357421875em;
}