.Block__Contact {
}

.Block__Contact > a {
    display: inline-block;
}
@media (width >= 410px) {
  .Block__Contact > a:first-child {
      margin-right: var(--space);
  }
}