.on-call {
  display: block;
  position: fixed;
  z-index: 100;
  bottom: var(--space);
  right: var(--space);
  max-height: 100%;
  width: 100%;
  max-width: 26rem;
  background: var(--color-sand);
  box-shadow: 0 8px 25px -5px #000;
  overflow: hidden;
  transition:
    border-radius 0.25s ease-in,
    max-width var(--default-transition-speed) ease-in,
    max-height var(--default-transition-speed) ease-in,
    right var(--default-transition-speed) ease-in,
    bottom var(--default-transition-speed) ease-in,
    left var(--default-transition-speed) ease-in;
}

.on-call.icon-size {
  fill: var(--color-red);
  padding: var(--space-half);
  position: fixed;
  z-index: 100;
  background: var(--color-sand);
  box-shadow: 0 8px 25px -5px #000;
  border-radius: 50%;
  cursor: pointer;
  max-width: 53px;
  max-height: 53px;
  transition:
    border-radius 0.5s ease-in,
    max-width var(--default-transition-speed) ease-in,
    max-height var(--default-transition-speed) ease-in,
    right var(--default-transition-speed) ease-in,
    bottom var(--default-transition-speed) ease-in,
    left var(--default-transition-speed) ease-in;
}

.on-call .on-call-open {
  background: transparent;
  height: 0;
  width: 53px;
  height: 53px;
  background: var(--color-red);
  padding: 0.75rem 0.85rem;
  cursor: pointer;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: opacity 0s ease-in;
}

.on-call.icon-size .on-call-open {
  transition: opacity 0.75s ease-in;
  opacity: 1;
}

.on-call .on-call-open svg {
  margin: 0 auto;
  fill: var(--color-sand);
}

.on-call-close {
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  right: 0.5rem;
  top: 0.5rem;
  background: var(--color-gold);
  border-radius: 50%;
}

.on-call-close::before,
.on-call-close::after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0.38rem;
  top: 0.9rem;
  width: 1.25rem;
  height: 2px;
  border: none;
  transform: rotate(45deg);
  background: var(--color-sand);
}
.on-call-close::after {
  transform: rotate(-45deg);
}

.on-call.icon-size .on-call-close {
  display: none;
}

.on-call.icon-size .on-call-content {
  opacity: 0;
  transition: opacity var(--default-transition-speed) ease-in;
}

.on-call-content {
  opacity: 1;
  max-height: 100vh;
  overflow: auto;
}

.on-call .on-call-image {
  overflow: hidden;
  max-height: 16rem;
}

.on-call img {
  background: #fff;
  padding-top: 2rem;
  display: block;
}

.on-call .content {
  padding: 0 var(--space) var(--space-half);
}

.on-call .phone-group a {
  color: var(--color-red);
}

.on-call .phone-group svg {
  width: 14px;
  fill: var(--color-red);
  margin: 0 0 -8px;
}

.on-call h3 {
  margin: 0.25rem 0 0;
  white-space: nowrap;
}

.on-call h3 + p {
  margin: 0 0 0.5rem;
  font-size: var(--type-size--1);
  white-space: nowrap;
}

.on-call p.small {
  font-size: var(--type-size--1);
  line-height: 1.35;
}

.on-call-copy {
  display: none;
}

@media (--viewport-menu-inverse) {
  .on-call {
    right: 0;
    bottom: 0;
    background: var(--color-black);
    color: #fff;
    max-width: 100%;
  }

  .on-call.icon-size {
    background: var(--color-black);
  }

  .on-call .phone-group {
    white-space: nowrap;
  }

  .on-call .phone-group a {
    color: var(--color-gold);
  }

  .on-call .phone-group svg {
    fill: var(--color-gold);
  }

  .on-call.icon-size {
    bottom: var(--space);
    right: var(--space);
  }

  .on-call .on-call-image {
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 7rem;
    overflow: hidden;
  }

  .on-call img {
    padding: 0;
    height: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    transform: scale(1.25);
  }

  .on-call .content {
    margin-left: 7rem;
    padding-bottom: 1rem;
  }

  .on-call .content p {
    margin-bottom: 0.5rem;
  }

  .on-call-close {
    width: 1.5rem;
    height: 1.5rem;
  }

  .on-call-close::before,
  .on-call-close::after {
    left: 0.25rem;
    top: 0.7rem;
    width: 1rem;
  }
}

@media (--viewport-menu) {
  .on-call-copy {
    display: block;
  }

  .on-call img {
    background: #fff;
    padding-top: 1rem;
  }
}
