@media (--viewport-menu) {
  body.contact .Masthead__Page {
    margin-bottom: 0px;
  }

  body.contact .Masthead__Page .Headline__Headline_level-1 {
    max-width: 28rem;
  }
}

@media (--viewport-menu) {
  .contact_vectors {
    display: flex;
    max-width: 50rem;
    margin: auto;
    align-items: stretch;
    margin-bottom: var(--space-triple);
  }
}

.contact_vectors > div {
  flex: 1 1 30%;
  padding-bottom: var(--space);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media (--viewport-menu) {
  .contact_vectors > div {
    border-right: var(--default-border-width) solid var(--color-black);
    align-items: center;
    padding: var(--space-half);
  }
  .contact_vectors > div:last-child {
    border-right: 0px;
  }
}

.contact_vectors .espanol {
  display: inline-block;
  margin-top: var(--space-third);
  font-style: italic;
}

.Link__Slab[data-parent="directions_link"] {
  font-size: var(--type-size--1);
  border-width: var(--default-border-width);
  color: var(--color-black);
}

.contact_form {
  max-width: 45rem;
  margin: auto;
  margin-top: var(--space-triple);
}

.contact_form .gform_title {
  font-size: var(--type-size-2);
  margin: 0px;
}

.contact_form .gform_heading {
  margin-bottom: var(--space);
}

.contact_form ul {
  list-style: none;
  margin: 0px; 
  padding: 0px;
}

.contact_form label {
  display: none;
}

.contact_form .gfield {
  padding: var(--space-quarter) 0px;
}
@media (--viewport-small) {
  .contact_form .gfield {
    padding: var(--space-quarter);
  } 
}

.contact_form .gfield .ginput_container {
  border: var(--default-border-width) solid var(--color-gold);
}

.contact_form input,
.contact_form select,
.contact_form textarea {
  appearance: none;
  width: 100%;
  border: 0px;
  background: transparent;
  padding: var(--space-quarter);
  display: block;
}

.contact_form input::placeholder,
.contact_form select,
.contact_form textarea::placeholder {
  font-family: var(--type-face-mechanical);
  font-size: var(--type-size--1);
  text-transform: uppercase;
  opacity: 0.25;
}

.contact_form .ginput_container_select {
  position: relative;
}
.contact_form .ginput_container_select:after {
  content: "";
  width: 1em;
  height: 100%;
  display: block;
  position: absolute;
  right: 1em;
  top: .5em;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path class="path1" fill="none" stroke="rgb(54, 57, 62)" stroke-width="3.1825" stroke-miterlimit="10" stroke-linecap="butt" stroke-linejoin="miter" d="M2 8.816l14.383 14.367 14.367-14.367"></path></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

.contact_form select {
  padding-top: var(--space-third);
  padding-bottom: var(--space-third);
}

.contact_form .gform_fields {
  display: flex;
  flex-wrap: wrap;
}

.contact_form .form_width-50,
.contact_form .form_width-100 {
    flex: 1 1 100%;  
}
@media (--viewport-small) {
  .contact_form .form_width-50 {
    flex: 1 1 50%;
  }
}

.gform_button[type="submit"] {
  width: auto;
  background-color: var(--color-gold);
  color: white;
  font-family: var(--type-face-mechanical);
  text-transform: uppercase;
  font-size: var(--type-size--1);
  letter-spacing: 0.1em;
  padding: var(--space-half) var(--space-double);
  margin: auto;
  margin-top: var(--space-half);
}

.contact_form .validation_error,
.contact_form .validation_message {
  font-style: italic;
  font-size: var(--type-size--1);
  color: var(--color-red);
}

.contact_footer-image {
  display: block;
}